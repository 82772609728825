
import { DefineComponent, defineComponent, onMounted, onUnmounted, Ref, ref, watch, reactive } from "vue";
import { useStore } from "vuex";
import AuthService from "@/services/AuthService";
import { useRouter } from "vue-router";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import ModalAtendimentos from "./components/modalAtendimentos.vue";
import ModalAtendimentosDetalhados from "./components/ModalAtendimentosDetalhados.vue";
import { Modal } from "bootstrap";
import useEmitter from "@/composables/Emmiter";
import { loaderLogo } from "@/core/helpers/config";
import Title from "@/components/Title.vue"
// import TicketsInfo from "@/views/dashboard/components/TicketsInfo.vue"
import { useTicketsStore } from "@/store/TicketsStore";
import { useAtendimentoStore } from "@/store/AtendimentoStore";
import ImplantacoesInfo from "./components/ImplantacoesInfo.vue";
import CardAtendimentos from "@/views/dashboard/components/CardAtendimentos.vue"
import moment from "moment";


interface DashboardFilter {
  idLojas: string;
  idGrupos: string;
  data: any;
  regiao: any;
}

interface DadosAtendimentos {
  title: string;
  quantity: number;
  percentage: string;
  value?: number;
  iconFilename: string;
  displayValorFaturado: boolean;
  mediaDiasParaRetorno?: number;
  line?: boolean
}

interface Loja {
  integracoes: any[];
  integrada: number;
  total: number;
  tempo?: number;
}

interface Recusas {
  produto: number;
  valor: number;
}

interface ContentDashboard {
  total: { qtd: number; valor: number };
  manual: { qtd: number; valor: number };
  buscaPlaca: { qtd: number; valor: number };
  aberto: { qtd: number; valor: number };
  enviado: { qtd: number; valor: number };
  fechado: { qtd: number; valor: number };
  vendido: { qtd: number; valor: number };
  cancelado: { qtd: number; valor: number };
  andamento: { qtd: number; valor: number };
  precificar: { qtd: number; valor: number };
  pendente: { qtd: number; valor: number };
  transmitido: { qtd: number; valor: number, os?:number, bazar?:number, valorOs?:number, valorBazar?:number };
  consulta: { qtd: number; valor: number };
  recusa: { qtd: number; valor: number };
  orcamentos: { qtd: number; valor: number };
  valorOfertado: { valor: number };
  produtos: { qtd: number };
  consumidorPreenchido: number
}

interface Store {
  finalPlano: string;
  id: number;
  inicioPlano: string;
  nome: string;
  grupo: string;
  plano: string;
  progress: number;
  status: number | boolean;
}

interface StoreFilter {
  nomeFantasia: string;
  grupo: string;
  data: any;
  status: string;
}

export default defineComponent({
  name: "Dashboard",

  components: { 
    ModalAtendimentos,
    Title,
    // TicketsInfo, Analize de tickets desativado no momento, ate resolver como será usado
    ModalAtendimentosDetalhados,
    ImplantacoesInfo,
    CardAtendimentos
},
  setup() {
    let auxModal;
    const emitter = useEmitter();
    const router = useRouter();
    const store = useStore();
    const isLoading: Ref<boolean> = ref(true);
    const isLoadingOS: Ref<boolean> = ref(true);
    const isLoadingStoresErps: Ref<boolean> = ref(true);
    const declinedIndicarLoader: Ref<boolean> = ref(false);
    const loadingDownloadAtendimento: Ref<boolean> = ref(false);
    const openDropdown: Ref<boolean> = ref(true);
    const updateChart: Ref<boolean> = ref(true);
    const updateChartOS: Ref<boolean> = ref(true);
    const mesHistLoja = ref("");
    const mesHistOS = ref("");
    const totalLojas: Ref<number> = ref(0);
    const lojasIntegradas: Ref<number> = ref(0);
    const ticketStore = useTicketsStore()
    const lojas: Ref<any[]> = ref([]);
    const lojasSelected: Ref<any[]> = ref([]);
    const allLojasIds: Ref<any[]> = ref([]);
    const loaderTodosAtendimentos: Ref<boolean> = ref(false);
    const loaderFinalizados: Ref<boolean> = ref(false);
    const loaderFaturados: Ref<boolean> = ref(false);
    const atendimentoStore = useAtendimentoStore();
    const count:Ref<number> = ref(1);
    const mediaGeral:Ref<number | null> = ref(null);
    
    const arrayDataCardsAtendimentos: Ref<DadosAtendimentos[]> = ref([]);

    const value1 = ref("");
    const value2 = ref([]);

    const storeFilter: Ref<StoreFilter> = ref({ nomeFantasia: "", grupo: "", data: "", status: "" });

    async function openModalTodosAtendimentos(id){
      //getAtendimentos passando id do grupo e a quantidade de valores retornados
      loaderTodosAtendimentos.value = true
      atendimentoStore.sendCardName("Todos os Atendimentos")
      const finalizados = false
      await atendimentoStore.getAtendimentos(82, finalizados, dashboardFilter.idLojas, dashboardFilter.idGrupos, dashboardFilter.regiao)
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      loaderTodosAtendimentos.value = false
    };

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    async function openModalAtendimentosFinalizados(id){
      //getAtendimentos passando id do grupo e a quantidade de valores retornados
      loaderFinalizados.value = true
      atendimentoStore.sendCardName("Atendimentos finalizados")
      const finalizados = true
      await atendimentoStore.getAtendimentos(82, finalizados,dashboardFilter.idLojas, dashboardFilter.idGrupos, dashboardFilter.regiao)
      await atendimentoStore.getDashboardItensAtendimentosDetalhados(82,finalizados,dashboardFilter.idLojas, dashboardFilter.idGrupos, dashboardFilter.regiao)
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      loaderFinalizados.value = false

    };

    async function openModalValorAtendimentos(id){
      //getAtendimentos passando id do grupo e a quantidade de valores retornados
      loaderFaturados.value = true
      atendimentoStore.sendCardName("Valor faturado nos atendimentos")
      const finalizados = true
      await atendimentoStore.getDashboardItensAtendimentosDetalhados(82,finalizados,dashboardFilter.idLojas, dashboardFilter.idGrupos, dashboardFilter.regiao)
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      loaderFaturados.value = false
    };

    const storeInt: Ref<Loja> = ref({
      integracoes: [],
      integrada: 0,
      total: 0,
      tempo: 0,
    });

    const declinedIndicar: Ref<Recusas> = ref({
      produto: 0,
      valor: 0,
    });

    const groupStore: Ref<any> = ref("");
    const lojaSpecify: Ref<any> = ref("");
    const specificFormatData = (date: any) => new Date(date).toISOString().substring(0, 19).replace("T", " ");

    const dashboardFilter: DashboardFilter = reactive({
      idLojas: "",
      idGrupos: "",
      data: "",
      regiao: "",
    });

    const contentDashboard: Ref<ContentDashboard> = ref<ContentDashboard>({
      total: { qtd: 0, valor: 0 },
      manual: { qtd: 0, valor: 0 },
      buscaPlaca: { qtd: 0, valor: 0 },
      aberto: { qtd: 0, valor: 0 },
      enviado: { qtd: 0, valor: 0 },
      fechado: { qtd: 0, valor: 0 },
      vendido: { qtd: 0, valor: 0 },
      cancelado: { qtd: 0, valor: 0 },
      andamento: { qtd: 0, valor: 0 },
      precificar: { qtd: 0, valor: 0 },
      pendente: { qtd: 0, valor: 0 },
      transmitido: { qtd: 0, valor: 0 },
      consulta: { qtd: 0, valor: 0 },
      recusa: { qtd: 0, valor: 0 },
      orcamentos: { qtd: 0, valor: 0 },
      valorOfertado: { valor: 0 },
      produtos: { qtd: 0 },
      consumidorPreenchido: 0
    });

    const dataAtualMounted: Ref<any> = ref([]);

    const changeDrop = () => {
      openDropdown.value = !openDropdown.value;
    };

    async function getLojas() {
      const res = await Api.get("getAllStores", { idGrupo: 82 });
      lojas.value = res.data.lojas;
      getIds()
    }

    const getMonthRange = () => {
      try {
        //essa parte pega o ultimo dia do mes atual, e o primeiro dia do mes atual.
        const today = new Date(); // Obtém a data atual
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Define o primeiro dia do mês atual
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Define o último dia do mês atual
        dataAtualMounted.value = [firstDayOfMonth, lastDayOfMonth]; // Define o intervalo do mês atual como um array em dataAtualMounted
        mesHistLoja.value = dataAtualMounted.value[1];
        mesHistOS.value = dataAtualMounted.value[0];
        /* essa parte pega o ultimo dia do mes atual, e o primeiro dia do mes anterior.
        const today = new Date(); // Obtém a data atual
        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Define o primeiro dia do mês anterior
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Define o último dia do mês atual
        // Ajusta a data do último dia do mês atual
        lastDayOfMonth.setDate(lastDayOfMonth.getDate());
        dataAtualMounted.value = [firstDayOfLastMonth, lastDayOfMonth]; // Define o intervalo do mês anterior e do mês atual como um array em dataAtualMounted
        */
      } catch (error) {
        console.log(error);
      } finally {
        value2.value = dataAtualMounted.value;
      }
    };

  const getLastDayOfMonth = (selectedDate) => {
    try {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();

      const lastDayOfMonth = new Date(year, month + 1, 0);

      return lastDayOfMonth;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

    const series: Ref<any> = ref([]);
    const chartOptionsAtendimentos = ref({
      chart: { width: 200, type: "pie" },
      labels: ["Busca Placa", "Busca Manual"],
      legend: { show: false },
      colors: ["#3699FF", "#203972"],
      stroke: {
        width: 0,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    const shortcuts = [
      {
        text: "This month",
        value: [new Date(), new Date()],
      },
      {
        text: "This year",
        value: () => {
          const end = new Date();
          const start = new Date(new Date().getFullYear(), 0);
          return [start, end];
        },
      },
      {
        text: "Last 6 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 6);
          return [start, end];
        },
      },
    ];

    const seriesOS: Ref<any> = ref([]);
    const chartOptionsOS = ref({
      chart: { width: 200, type: "donut" },
      labels: ["Finalizados", "Orçamentos", "Consultas"],
      legend: { show: false },
      colors: ["#1BC5BD", "#3699FF", "#FFB822"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      align: "center",
      verticalAlign: "middle",
      text: "CAMP Tec",
      stroke: {
        width: 0,
      },
    });

    const shortcutsOS = [
      {
        text: "This month",
        value: [new Date(), new Date()],
      },
      {
        text: "This year",
        value: () => {
          const end = new Date();
          const start = new Date(new Date().getFullYear(), 0);
          return [start, end];
        },
      },
      {
        text: "Last 6 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 6);
          return [start, end];
        },
      },
    ];

    const seriesGrafico: Ref<any> = ref([]);

    const MONTHS = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
    const MONTHS_FULL = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    const categories: Ref<any> = ref([
      new Date("2018-01-19T00:00:00.000Z"),
      new Date("2018-02-19T01:30:00.000Z"),
      new Date("2018-03-19T02:30:00.000Z"),
      new Date("2018-04-19T03:30:00.000Z"),
      new Date("2018-05-19T04:30:00.000Z"),
      new Date("2018-06-19T05:30:00.000Z"),
      new Date("2018-07-19T06:30:00.000Z"),
    ]);

    const categoriesWithMonth = seriesGrafico.value?.data?.map(item => {
      const date = new Date(item.x);
      return MONTHS[date.getMonth()] + "/" + date.getFullYear();
    });
    const chartOptionsGrafico = ref({
      chart: { height: 350, type: "area", toolbar: { show: false } },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth" },
      xaxis: {
        type: "category",
        categories: categoriesWithMonth,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const mes = w.config.series[seriesIndex].data[dataPointIndex].x;
          const adicionadas = w.config.series[seriesIndex].data[dataPointIndex].z;
          const acumuladas = w.config.series[seriesIndex].data[dataPointIndex].y;
          return `<div class="p-4"><strong> ${mes}</strong> <br> Adicionadas:<strong> ${adicionadas}</strong> <br> Total de lojas: <strong>${acumuladas}</strong></div>`;
        },
      },
      yaxis: {
        show: false,
      },
    });

    // "chartOptionsHistOS" :series="seriesHistOS"
    // const categoriesOS: Ref<any> = ref(["01/01/2011 GMT", "01/02/2011 GMT", "01/03/2011 GMT", "01/04/2011 GMT", "01/05/2011 GMT", "01/06/2011 GMT"]);
    // const categoriesOSs: Ref<any> = ref(["11/01/2022 GMT", "12/01/2022 GMT", "01/01/2023 GMT", "02/01/2023 GMT", "03/01/2023 GMT"]);
    const seriesHistOS: Ref<any> = ref([]);
    const categoriesOS: Ref<any> = ref([]);
    let orcamentos = [];
    let finalizados = [];
    let consultas = [];

    const chartOptionsHistOS = ref({
      chart: {
        type: "bar",
        height: 350,
        colors: ["#1BC5BD", "#3699FF", "#FFB822"],
        stacked: true,
        // stackType: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          // endingShape: "rounded",
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: categoriesOS.value,
      },
      tooltip: {
        enabled: true,
        shared: false,
        followCursor: false,
        style: {
          fontSize: "14px",
          fontWeight: 600,
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const mes = MONTHS_FULL[dataPointIndex];
          const value = w.config.series[seriesIndex].data[dataPointIndex];

          const orcamentosValue = w.config.series[0].data[dataPointIndex];
          const finalizadosValue = w.config.series[1].data[dataPointIndex];
          const consultasValue = w.config.series[2].data[dataPointIndex];

          // Calcula o total do valor da série selecionada
          const totalValue = orcamentosValue + finalizadosValue + consultasValue;

          // Calcula a porcentagem em relação ao total
          const percent = ((value / totalValue) * 100).toFixed(2);

          return `<div class="p-4"><strong></strong>${w.config.series[seriesIndex].name}: <strong>${percent}%</strong></div>`;
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    });

  
    async function getAttendanceDashboard() {
      isLoading.value = true;
      try {
        const { data } = await Api.get("getAttendanceDashboard", { ...dashboardFilter });
        const atd = data.atendimento;
        getDataForCards(atd);
        contentDashboard.value = {
          total: atd.total,
          manual: atd.manual,
          buscaPlaca: atd.buscaPlaca,
          aberto: atd.aberto,
          enviado: atd.enviado,
          fechado: atd.fechado,
          vendido: atd.vendido,
          cancelado: atd.cancelado,
          andamento: atd.andamento,
          precificar: atd.precificar,
          pendente: atd.pendente,
          transmitido: atd.transmitido,
          consulta: atd.consulta,
          recusa: atd.recusa,
          produtos: { qtd: atd.transmitido.qtdProduto },
          consumidorPreenchido: atd.novoTotal[0].consumidor_preenchido,
        //   valorOfertado: {
        //     valor: atd.andamento.valor + atd.enviado.valor + atd.vendido.valor + atd.precificar.valor + atd.pendente.valor + atd.transmitido.valor,
        //   },
          valorOfertado: {
            valor: atd.novoTotal[0].Valor_ofertado
          },
          orcamentos: {
            qtd:
              atd.andamento.qtd + atd.enviado.qtd + atd.vendido.qtd + atd.precificar.qtd + atd.pendente.qtd + (atd.fechado.qtd - atd.transmitido.qtd),
            valor:
              atd.andamento.valor +
              atd.enviado.valor +
              atd.vendido.valor +
              atd.precificar.valor +
              atd.pendente.valor +
              (atd.fechado.valor - atd.transmitido.valor),
          },
        };
        const { buscaPlaca, manual, transmitido, orcamentos, aberto } = contentDashboard.value;
        series.value = [buscaPlaca.qtd, manual.qtd];
        seriesOS.value = [transmitido.qtd, orcamentos.qtd, aberto.qtd];
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    }

    function getDataForCards(data){
      const quantityClientesRecorrentes = Number(data.transmitido.qtd) - data.novoTotal[0].Novos_clientes;

      const updatedItems = [
          {
              title: "Consultas",
              quantity: Number(data.novoTotal[0].atendimentos),
              percentage: getPercentage(Number(data.novoTotal[0].atendimentos), Number(data.novoTotal[0].atendimentos)),
              iconFilename: "search-duotone.svg",
              displayValorFaturado: false
          },
          {
              title: "Orçamentos",
              quantity: Number(data.novoTotal[0].Atendimentos_orçados),
              percentage: getPercentage(Number(data.novoTotal[0].Atendimentos_orçados), Number(data.novoTotal[0].atendimentos), 1),
              value: data.novoTotal[0].Valor_orçado,
              iconFilename: "file-invoice-dollar-duotone.svg",
              displayValorFaturado: true,
          },
          {
              title: "Finalizados",
              quantity: Number(data.transmitido.qtd),
              percentage: getPercentage(Number(data.transmitido.qtd), Number(data.novoTotal[0].Atendimentos_orçados)),
              value: data.transmitido.valor,
              iconFilename: "clipboard-check-duotone.svg",
              displayValorFaturado: true,
              mediaDiasParaRetorno: data.novoTotal[0].media_dias_para_retorno,
              line: true
          },
          {
              title: "Novos Clientes",
              quantity: data.novoTotal[0].Novos_clientes,
              percentage: getPercentage(data.novoTotal[0].Novos_clientes, data.transmitido.qtd),
              iconFilename: "flag1.svg",
              displayValorFaturado: false
          },
          {
              title: "Recorrentes",
              quantity: quantityClientesRecorrentes,
              percentage: getPercentage(quantityClientesRecorrentes, data.transmitido.qtd),
              iconFilename: "car-duotone.svg",
              displayValorFaturado: false
          }
      ]
      arrayDataCardsAtendimentos.value = updatedItems
    }

    function getPercentage(value1: number, value2: number, decimal = 1): string{
      if (value2 === 0) {
        return "0%";
      }
        const percentage = (value1 / value2) * 100;
        return `${percentage.toFixed(decimal)}%`;
    }

    async function getDashboardIntegratedStores() {
      isLoadingStoresErps.value = true;
      try {
        const { data } = await Api.get("getDashboardIntegratedstores", {
          ...dashboardFilter,
        });
        const totalLojas = data.integracoes.reduce((acc, cur) => acc + cur.lojas, 0);
        storeInt.value = {
          integracoes: data.integracoes
            .map(nomeIntegracao => nomeIntegracao)
            .sort((a: any, b: any) => b.lojas - a.lojas || a.nome.localeCompare(b.nome)),
          integrada: 0,
          total: totalLojas,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingStoresErps.value = false;
      }
    }

    async function getReasonsDecline() {
      declinedIndicarLoader.value = true
      try {
        const { data } = await Api.get("getDashboardOfferDeclined");

        const produtoQtd = data.ofertasRecusadas.filter(obj => obj.nome.toLowerCase().includes("produto")).map(obj => obj.qtd)[0];
        const valorQtd = data.ofertasRecusadas.filter(obj => obj.nome.toLowerCase().includes("valor")).map(obj => obj.qtd)[0];

        declinedIndicar.value = {
          produto: typeof produtoQtd === "number" ? produtoQtd : 0,
          valor: typeof valorQtd === "number" ? valorQtd : 0,
        };
      } catch (error) {
        console.log(error);
      }
      declinedIndicarLoader.value = false

    }

    async function getDashboardHistoricoLojas() {
      updateChart.value = true;
      try {
        const { data } = await Api.get("getDashboardHistoricoLojas", { ...dashboardFilter, data: formatDateHistLoja(mesHistLoja.value) });
        totalLojas.value = data.total;
        lojasIntegradas.value = data.integrada;


        // Ordena o array historico pela data, em ordem crescente
        const historicoOrdenado = data.historico.reverse();
        //sort((a, b) => new Date(a.mes).getTime() - new Date(b.mes).getTime());

        // Cria a série de dados para o gráfico
        const series: Ref<any> = ref(
          historicoOrdenado.map(item => {
            const formattedDate = formatDate(item.mes);
            return {
              x: formattedDate,
              y: item.acumulado,
              z: item.total,
            };
          })
        );
        seriesGrafico.value = [
          {
            name: "Total",
            data: series.value.map(item => ({ x: item.x, y: item.y, z: item.z })),
          },
        ];
        
      } catch (error) {
        console.log(error);
      } finally {
        updateChart.value = false;
      }
    }

    async function getDashboardHistoricoOs() {
      updateChartOS.value = true;
      try {
        const { data } = await Api.get("getDashboardHistoricoAtendimentos", { ...dashboardFilter, data: formatDateHistLoja(mesHistOS.value) });

        const categories = data.atendimento
          .map(item => ({ mes: new Date(item.mes), formatted: formatDateOS(item.mes) }))
          .sort((a, b) => a.mes.getTime() - b.mes.getTime())
          .map(item => item.formatted);

        const orcamentos = data.atendimento.map(item => item.orcamentos);
        const finalizados = data.atendimento.map(item => item.finalizados);
        const consultas = data.atendimento.map(item => item.consultas); // Define as categorias do eixo x

        // seriesHistOS.value =
        // console.log('data em ingles', categories)
        chartOptionsHistOS.value.xaxis.categories = categories;
        seriesHistOS.value = [
          { name: "Orcamentos", data: orcamentos },
          { name: "Finalizados", data: finalizados },
          { name: "Consultas", data: consultas },
        ];

        // seriesHistOS.value = [
        //   { name: "PRODUCT A", data: [44, 55, 41, 67, 22, 43] },
        //   {
        //     name: "PRODUCT B",
        //     data: [13, 23, 20, 8, 13, 27],
        //   },
        //   {
        //     name: "PRODUCT C",
        //     data: [11, 17, 15, 15, 21, 14],
        //   },
        //   {
        //     name: "PRODUCT D",
        //     data: [21, 7, 25, 13, 22, 8],
        //   },
        // ];
      } catch (error) {
        console.log(error);
      } finally {
        updateChartOS.value = false;
      }
    }

    const atendimentoIdsAgrupados: Ref<any> = ref([])



    const getIds = () => {
      allLojasIds.value = lojas.value.map((item) => item.codConcessionaria)
    }

    function formatDateOS(dateStr) {
      //   const date = new Date(dateStr);
      const splitStringDate = dateStr.split("-");
      const date = new Date(
        parseInt(splitStringDate[0]),   //ano
        parseInt(splitStringDate[1]) - 1, //mes
        parseInt(splitStringDate[2])    //dia
      );

      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
      // const day = date.getDate().toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      // const year = date.getFullYear().toString();
      // const hour = date.getUTCHours().toString().padStart(2, "0");
      // const minute = date.getUTCMinutes().toString().padStart(2, "0");
      // const second = date.getUTCSeconds().toString().padStart(2, "0");
      return month;
    }

    function formatDate(dateStr) {
      // Criar uma nova instância de Date a partir da string de data fornecida
      const date = new Date(dateStr);

      // Obter a hora atual em UTC+3
      date.setUTCHours(date.getUTCHours() + 3);
      const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear().toString().substr(-2);
      return `${month}/${year}`;
    }

    const formatDateHistLoja = date => {
      // Verifica se a data é válida
      if (date instanceof Date && !isNaN(date.valueOf())) {
        // Obtém o ano, mês e dia da data
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        // Formata a data no formato desejado (YYYY/MM/DD)
        return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T03:00:00.000Z`;
      }

      return null;
    };

    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.nivelCargo;

    function goToHome() {
      router.push({ name: "login" });
    }

    // watch(
    //   () => value2.value,
    //   () => {
    //    ticketStore.sendDate([specificFormatData(value2.value[0]), specificFormatData(value2.value[1])])
    //   }
    // )

    async function enviarEmit() {
      await emitter.emit("Dashboard", {
        dataSelecionada: `${specificFormatData(value2.value[0])},${specificFormatData(value2.value[1])}`,
      });
    }

    emitter.on("filtro-dashboard", async params => {
      dashboardFilter.idGrupos = params.grupoEloja.grupo
      dashboardFilter.idLojas = params.grupoEloja.loja
      dashboardFilter.data = params.grupoEloja.data
      dashboardFilter.regiao = params.grupoEloja.regiao
    });


    async function baixaAtendimento() {  
       
      try{
        loadingDownloadAtendimento.value = true
        const response = await Api.get("DownloadAtendimentos", { ...dashboardFilter }, { responseType: 'blob' });
        baixaArquivo(response, 'dashboard')
      }catch(error){
        console.log(error)
      }finally{
        loadingDownloadAtendimento.value = false
      }

    }

    async function baixaHistoricoLoja() {

      const response = await Api.get("downloadHistoricoLojas", { ...dashboardFilter, data: formatDateHistLoja(mesHistLoja.value) }, { responseType: 'blob' });

      baixaArquivo(response, 'lojas')

    }

    function baixaArquivo(blobParam:any, name:string) {

      // window.open( URL.createObjectURL(response) )

      const url = URL.createObjectURL(new Blob([blobParam]));
      const link = document.createElement('a');
      link.href = url;
      link.download = `${name}-${new Date().toISOString()}.xlsx`
      link.click();
      URL.revokeObjectURL(url);

    }


    watch(
      () => value2.value,
      () => {
        atendimentoStore.sendDate(`${specificFormatData(value2.value[0]).split(" ")[0]},${specificFormatData(value2.value[1]).split(" ")[0]}`)
        dashboardFilter.idGrupos = dashboardFilter.idGrupos ? dashboardFilter.idGrupos : '82' 
        dashboardFilter.data = `${specificFormatData(value2.value[0])},${specificFormatData(value2.value[1])}`
        dashboardFilter.idLojas = dashboardFilter.idLojas ? dashboardFilter.idLojas : ""
        dashboardFilter.regiao = dashboardFilter.regiao ? dashboardFilter.regiao : ""
      }
    );

    watch(
      () => mesHistLoja.value,
      () => {
        dashboardFilter.idGrupos = dashboardFilter.idGrupos ? dashboardFilter.idGrupos : '82' 
        getDashboardHistoricoLojas()
      }
    );

    watch(
      () => mesHistOS.value,
      () => {
        dashboardFilter.idGrupos = dashboardFilter.idGrupos ? dashboardFilter.idGrupos : '82' 
        getDashboardHistoricoOs()
      }
    );


    watch(
      () => [dashboardFilter.idLojas, dashboardFilter.idGrupos],
      () => {
        dashboardFilter.idGrupos = dashboardFilter.idGrupos ? dashboardFilter.idGrupos : '82' 
        getDashboardHistoricoOs(), getDashboardHistoricoLojas();
      }
    );

    watch(
      () => [dashboardFilter.idLojas, dashboardFilter.idGrupos, dashboardFilter.data, dashboardFilter.regiao],
      () => {
        dashboardFilter.idGrupos = dashboardFilter.idGrupos ? dashboardFilter.idGrupos : '82';
        getAttendanceDashboard();
        getDashboardIntegratedStores();
        // getReasonsDecline();
        // getDashboardHistoricoLojas();
        // getDashboardHistoricoOs();
        enviarEmit();
      }
    );

    // testar, encapsular a funcao de historicos e usar no oumounted, com uma condicao na funcao para q se existe tal parametro, execute, na montagem.
    onMounted(() => {
      getMonthRange();
      getLojas();
      getReasonsDecline();
      // getAttendanceDashboard();
      // getDashboardIntegratedStores();
      // getDashboardHistoricoLojas();
      // getDashboardHistoricoOs();

    });



    return {
      goToHome,
      value1,
      value2,
      shortcuts,
      series,
      chartOptionsAtendimentos,
      shortcutsOS,
      seriesOS,
      chartOptionsOS,
      groupStore,
      lojaSpecify,
      getAttendanceDashboard,
      contentDashboard,
      isLoading,
      seriesGrafico,
      chartOptionsGrafico,
      openDropdown,
      changeDrop,
      isLoadingStoresErps,
      storeInt,
      declinedIndicar,
      dashboardFilter,
      chartOptionsHistOS,
      seriesHistOS,
      storeFilter,
      console,
      ElConfigProvider,
      ptBr,
      dataAtualMounted,
      openModal,
      openModalTodosAtendimentos,
      isLoadingOS,
      categoriesOS,
      loaderLogo,
      mesHistLoja,
      updateChart,
      updateChartOS,
      mesHistOS,
      totalLojas,
      lojasIntegradas,
      baixaHistoricoLoja,
      baixaAtendimento,
      loadingDownloadAtendimento,
      lojas,
      lojasSelected,
      allLojasIds,
      openModalAtendimentosFinalizados,
      openModalValorAtendimentos,
      atendimentoStore,
      loaderFinalizados,
      loaderTodosAtendimentos,
      loaderFaturados,
      count,
      mediaGeral,
      declinedIndicarLoader,
      arrayDataCardsAtendimentos
    };
  },
});
